import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/views/Login";
import Register from "./components/views/Register";
import Forgot from "./components/views/Forgot";
import Dashboard from "./components/views/Dashboard";
import edit_profile from "./components/views/edit-profile";
import about from "./components/views/about";
import edit_social from "./components/views/edit-social";
import video from "./components/views/video";
import link from "./components/views/edit-link";
import edit_contact from "./components/views/edit-contact";
import changepassword from "./components/views/change-password";
import shareinfo from "./components/views/share-info";
import public_profile from "./components/views/public-profile";
import update_link from "./components/views/update-link";
import contact_list from "./components/views/contact-list";
import No_account from "./components/views/no-account";
import Manage_profile from "./components/views/manage-profiles";
import Setup_profile from "./components/views/setup-profile";

const Auth = () => {
  return (
    <Router>
      <Switch>
        <Route path='/auth/login' component={Login} />
        <Route path='/auth/register' component={Register} />
        <Route path='/auth/forgot-password' component={Forgot} />
        <Route path='/no-account' component={No_account} />
         <Route path='/my-profile/' component={Dashboard} />
          <Route path='/manage-profiles' component={Manage_profile} />
          <Route path='/setup-profiles' component={Setup_profile} />
         <Route path='/edit-profile' component={edit_profile} />
         <Route path='/edit-bio' component={about} />
         <Route path='/edit-social' component={edit_social} />
         <Route path='/edit-video' component={video} />
         <Route path='/edit-link' component={link} />
         <Route path='/edit-contact' component={edit_contact} />
         <Route path='/change-password' component={changepassword} />
         <Route path='/share-info' component={shareinfo} />
         <Route path='/update-link' component={update_link} />
         <Route path='/contact-list' component={contact_list} />
         <Route path='/' component={public_profile} />
      </Switch>
    </Router>
  );
}

export default Auth;

import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_Bio extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           acactive: {},
           errors: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           succMsg: {},
    	   succclass: ['head_err_msg'],
    	   firstC: {},
    	   export_btn: [''],
		}
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_contact_details();
		}
		
		
    }
    
    get_contact_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let search = '';

		if (fields.search != ''&& fields.search != 'undefined' ) {
			search = "&search="+fields.search;
		}else{
			search = '';
		}
		let data = this.state.data;
		let type = 'contact-list' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id+search
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				 fields['count'] = res.count;  
				 if (res.export_value == "on") {
				 	fields['export_value'] = res.export_value; 
				 	fields['export_value_url'] = 'https://dev.smarttap.world/my-account/team-dashboard?action=export-user&user_id='+user_id; 
				 }
				 if (res.export_value == "off") {
				 	fields['export_value'] = res.export_value;
				 	fields['export_value_url'] ='https://dev.smarttap.world/user-export-contact/?action=user-export-contact&user_id='+user_id
				 }
				   
				 data = res.data;
				 this.setState({fields});
				 this.setState({data});
				
				
			}else {
				fields['count'] = 0;  
				 this.setState({fields});
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		
			}
		})
		return false;
	}

   
	

    handleChange(field, e){         
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
        this.get_contact_details();
    }
    changeAC(i){
		let acactive =this.state.acactive;
		acactive = "collapse "+i;
		this.setState({acactive});
	}
	
	
	
	
  render() {
	let data = this.state.data;
	let firstC = this.state.firstC;
	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
	let user_id=sess_data.user_id
	let export_value_url = this.state.fields.export_value_url;
	let export_value = this.state.fields.export_value;
	let contact_list = data.length > 0
		&& data.map((item, i) => {
			let phone= "tel:"+item.phone;
			let emailto= "mailto:"+item.email;
			let comp= item.company;
			let we_met_at= item.we_met_at;
			let firstCHar = item.name.charAt(0);
			let fc = '';
			let alfbtc = '';
			let target = i
			if (firstCHar != firstC) {
				fc = firstCHar;
				firstC = firstCHar;
				alfbtc= "alfbtc";
			}else{
				fc = '';
				firstC = firstC = firstCHar;
				alfbtc ="alfbtc not"
			}
		let saveurl = 'https://dev.smarttap.world/save-contact/?id='+item.id
		let export_value = this.state.fields.export_value;
		return (
			<div className="card">
				<p className={alfbtc}>{fc}</p>
				<div className="card-header" onClick={() => this.changeAC(i)} id="faqhead1">
					<a href="javascript:void(0)" className={this.state.acactive === "collapse "+i ? "btn btn-header-link active" : "btn btn-header-link"} data-toggle="collapse" data-target={target}
					aria-expanded="true" aria-controls={i}>{item.name} <span className="arrow-down"></span></a>
				</div>

				<div id={i} className={this.state.acactive === "collapse "+i ? "collapse in" : "collapse"} aria-labelledby="faqhead1" data-parent="#faq">
					<div className="card-body">
						<div className="contsct-sec">
							<div className="info-box">
								<span className="info-icon"><img src={cell_phone} /></span>
								<p className="contacts"><a href={phone} className="">{item.phone}<span className="errow"><img src={next} /></span></a></p>
							</div>
							<div className="info-box">
								<span className="info-icon"><img src={email} /></span>
								<p className="contacts"><a href={emailto} className="">{item.email} <span className="errow"><img src={next} /></span></a></p>
							</div>
							{item.company ? (
							<div className="info-box comp-wemet">
								<span className="info-icon icofont-company"></span>
								<p className="contacts">{item.company}</p>
							</div>
							):(<p></p>)}
							{item.we_met_at ? (
							<div className="info-box comp-wemet">
								<span className="info-icon icofont-meetup"></span>
								<p className="contacts">{item.we_met_at}</p>
							</div>
							):(<p></p>)}
							
							<div className="button-sec">
								<div className="action">
									<a href={saveurl}><button type="button" className="stap-button">Save Contact</button></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		)
	}, this);
    return (
    	<div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div class="main-content">
			<div class="col-xs-12 name-hrdr">
				<h2>Connections ({this.state.fields.count})</h2>
				{export_value ?(
					<a href={export_value_url}><span className="export-connection icofont-download"></span></a>
				):(<p></p>)}
					
			</div>
			
			<div class="profile-list-sec">
				<div class="info-section">
					<div class="field">
						<span class="icofont-search-1"></span>
						<input type="text" placeholder="Search contacts..." onChange={this.handleChange.bind(this, "search")} value={this.state.fields['search']} name="search"/>
					</div>
					
					<div class="accordion" id="faq">
					    {contact_list}
					</div>
					
				</div>
				
			</div>
		</div>
		
	</div>
</div>


    );
  }
}

export default Edit_Bio;

import { useState } from "react";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import fetch from 'isomorphic-fetch';
import API_BASE from '../constant';
import {reactLocalStorage} from 'reactjs-localstorage';
import  { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import helpers from '../../helper/helpers';

//Import Images
import logo from '../../assets/images/logo.svg';
import qr_code from '../../assets/images/qr-code.png';
import profile from '../../assets/images/profile.jpeg';
import edit from '../../assets/images/edit.png';
import Linkedin from '../../assets/images/Linkedin.svg';
import next from '../../assets/images/next.png';
import cell_phone from '../../assets/images/cell_phone.png';
import email from '../../assets/images/email.png';
import website from '../../assets/images/website.png';
import location from '../../assets/images/location.png';




class Edit_social extends Component {
	constructor(props){
	
       super(props);
       this.state = {
           fields: {},
           social_fields: {},
           errors: {},
           succMsg: {},
           data: [],
           dynamiclass: ['head_err_msg'],
           succClass: ['head_err_msg'],
           values: [],
           fields_count: 0,
           selcted_social_network: [],
           social_network: {},
		   disable:['disable'],
		   changeText:["Save"],
           disabled: false,
		   
		}
		if(!helpers.check_login()){
			this.props.history.push('/auth/login')
		}else{
			this.get_social_details();
			let social_network = this.state.social_network;
			social_network['Select-Network']={key: 'Select_Network', value: 'Select Network',url:''}
			social_network['Anchor']={key: 'Anchor', value: 'Anchor',url:'https://anchor.fm/'}
			social_network['Apple_Podcast']={key: 'Apple_Podcast', value: 'Apple Podcast',url:'https://podcasts.apple.com/'}
			social_network['Calendly']={key: 'Calendly', value: 'Calendly',url:'https://calendly.com/'}
			social_network['Facebook']={key: 'Facebook', value: 'Facebook',url:'https://facebook.com/'}
			social_network['Github']={key: 'Github', value: 'Github',url:'https://github.com/'}
			social_network['Instagram']={key: 'Instagram', value: 'Instagram',url:'https://www.instagram.com/'}
			social_network['Linkedin']={key: 'Linkedin', value: 'Linkedin',url:'https://linkedin.com/in/'}
			social_network['Linkedin_company']={key: 'Linkedin_company', value: 'Linkedin company',url:'https://linkedin.com/company/'}
			social_network['Line']={key: 'Line', value: 'Line',url:'https://line.me/ti/p/'}
			social_network['Messenger']={key: 'Messenger', value: 'Messenger',url:'https://m.me/'}
			social_network['Metamask']={key: 'Metamask', value: 'Metamask',url:'https://metamask.app.link/send/'}
			social_network['Paypal']={key: 'Paypal', value: 'Paypal',url:'https://paypal.me/'}
			social_network['Snapchat']={key: 'Snapchat', value: 'Snapchat',url:'https://snapchat.com/add/'}
			social_network['Sound_Cloud']={key: 'Sound_Cloud', value: 'Sound Cloud',url:'https://soundcloud.com/'}
			social_network['Spotify']={key: 'Spotify', value: 'Spotify',url:'https://open.spotify.com/'}
			social_network['Tiktok']={key: 'Tiktok', value: 'Tiktok',url:'https://vm.tiktok.com/'}
			social_network['Twitch']={key: 'Twitch', value: 'Twitch',url:'https://www.twitch.tv/'}
			social_network['Twitter']={key: 'Twitter', value: 'Twitter',url:'https://twitter.com/'}
			social_network['Vimeo']={key: 'Vimeo', value: 'Vimeo',url:'https://venmo.com/'}
			social_network['Whatsapp']={key: 'Whatsapp', value: 'Whatsapp',url:'https://wa.me/'}
			social_network['Youtube']={key: 'Youtube', value: 'Youtube',url:'https://youtube.com/'}
			this.setState({social_network});
			//console.log(this.state.social_network)
			
			
			
		}
		this.handleChange = this.handleChange.bind(this);
		
	
		
		
		
    }
    
    
   
    
	


  
  addClick(){
    this.setState(prevState => ({ values: [...prevState.values, '']}))
    let fields_count=this.state.fields_count;
    fields_count=fields_count + 1;
    this.setState({ fields_count });
     const dynamiclass = 'head_err_msg';
    this.setState({ dynamiclass })
     const succClass = 'head_err_msg';
    this.setState({ succClass })
    
    
  }
  
  removeClick(i){
     let values = [...this.state.values];
     values.splice(i,1);
     this.setState({ values });
     let fields_count=this.state.fields_count;
     fields_count=fields_count- 1;
     this.setState({ fields_count });
     let fields = this.state.fields;
     let updated_fields={};
	 let idx=0;
	 for (var j = 0; j <= fields_count; j++) {
     	if(j!=i){
     		let key_1='social_media_'+idx;
     		let val_1=this.state.fields["social_media_"+j];
     		let key_2='social_text_'+idx;
     		let val_2=this.state.fields["social_text_"+j];
        	updated_fields[key_1] = val_1; 
        	updated_fields[key_2] = val_2; 
        	idx++;
     	}
	 }
    
    fields = updated_fields;
	this.setState({fields});
	const dynamiclass = 'head_err_msg';
    this.setState({ dynamiclass })
     const succClass = 'head_err_msg';
    this.setState({ succClass })
    
  }
  handleChange(field, e){  
 		 
        let fields = this.state.fields;
        fields[field] = e.target.value;  
        this.setState({fields});
        
}
selHandleChange(field, e){  
 		 
        let fields = this.state.fields;
        let social_fields = this.state.social_fields;
        let social_network = this.state.social_network;
        
        fields[field] = e.target.value;  
        let val1=e.target.value
        social_fields[field]= social_network[val1].url
        this.setState({fields});
        this.setState({social_fields});
        
}
    
    get_social_details() {
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		let fields = this.state.fields;
		let type = 'get-social-link' ;
		let LOGIN_API = API_BASE.concat(type);
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "user_id="+user_id
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			
			if (res.status == 'True') {
				
				let fields = JSON.parse(res.data);
				let values = [];
				let fields_count = res.social_count;
				let social_fields = this.state.social_fields;
        		let social_network = this.state.social_network;
				if(fields_count>=0){
				for(let i=0; i<=fields_count;i++){
					values.push(i);
					let val1=fields['social_media_'+i];
					social_fields['social_media_'+i]=social_network[val1].url;
					this.setState({social_fields});
				}
				}
				
				//values: [{"ss","ff"}];
				this.setState({fields});
				console.log('fields');
				console.log(fields)
				this.setState({values});
				fields_count=parseInt(fields_count) + 1;
				this.setState({fields_count});
				
				
				
			}
		})
		return false;
	}

    handleValidation(e){
		
		
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let fields_count=this.state.fields_count;
        for (var j = 0; j < fields_count; j++) {
     		if(!fields["social_text_"+j]){
             formIsValid = false;
          	 errors["social_text"] = "Text cannot be empty!";
        	}
        	if(!fields["social_media_"+j]){
             formIsValid = false;
             errors["social_media"] = "Drop down cannot be empty!";
        	}
	 	}
       this.setState({errors: errors});
       return formIsValid;
	}

	saveSocial(e) {
		
		let changeText = this.state.changeText;
		changeText = 'Please wait...';
		this.setState({ changeText,disabled:true })
		let fields = JSON.stringify(this.state.fields);
		let fields_count=this.state.fields_count;
		fields_count=parseInt(fields_count) -1;
		let type = 'add-social-link' ;
		let LOGIN_API = API_BASE.concat(type);
    	let sess_data=JSON.parse(window.sessionStorage.getItem('login_session'));
		let user_id=sess_data.user_id
		fetch(LOGIN_API, {
			method: 'POST',
			headers: new Headers({
	            'Content-Type': 'application/x-www-form-urlencoded', 
			}),
			body : "key=social-link&value="+fields+"&user_id="+user_id+"&social_count="+fields_count+""
			})
			.then((result) => {
			  return result.json();
		}).then((res) => {
			this.setState({ changeText: "Save",disabled:false })
			if (res.status == 'True') {
				
				let succMsg={};
				succMsg['msg']=res.msg;
				this.setState({succMsg: succMsg});
				const succClass = 'alert alert-success is_error';
         	 	this.setState({ succClass })
         	 	window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
			    setTimeout(() => {
				  this.props.history.push('/my-profile')
				}, 2000);
			}else {
				let errors={};
				errors['err']=res.msg;
				this.setState({errors: errors});
				const dynamiclass = 'alert alert-danger is_error';
           		this.setState({ dynamiclass });
           		window.scrollTo({
			      top: 0, 
			      behavior: 'smooth'
			    });
           		
			}
		})
	}
	
	socialSubmit(e){
        e.preventDefault();
       
        if(this.handleValidation(e)){
        	 const dynamiclass = 'head_err_msg';
         	 this.setState({ dynamiclass })
	         this.saveSocial(e);
	    }else{
	      const dynamiclass = 'alert alert-danger is_error';
          this.setState({ dynamiclass });
          let errors2=this.state.errors;
	    }

    }
    
    createUI(){
    
		let social_network=this.state.social_network;
		var social_links = Object.keys(social_network).map(function(key) {
			//console.log()
    		return (<option value={key}>{social_network[key].value}</option>)
		});
     	return this.state.values.map((el, i) => 
         	<div key={i}>
    	   	
    	   	 <div className="field-hdng">
							<span className="left">Social network</span>
							<span className="right">
								<button type="button" className="button css-gocinq" data-testid="delete-button">
									<span className="text" onClick={this.removeClick.bind(this, i,)}>Delete</span>
								</button>
							</span>
						</div>
						
						<div className="field">
							<span className="select" >
								<select  value= {this.state.fields["social_media_"+i]}  name={`social_media_${i}` } onChange={this.selHandleChange.bind(this,"social_media_"+i )}>
								{social_links}
							</select>
							</span>
							<span className="input">
								 <input type="text" name={`social_text_${i}` } value= {this.state.fields["social_text_"+i]} onChange={this.handleChange.bind(this, "social_text_"+i)} />
							</span>
							
						</div>
						<span>{this.state.social_fields["social_media_"+i]}{this.state.fields["social_text_"+i]}</span>
         	</div>          
     	)
  	}

    
  
	
	
	
	
  render() {
  
  
	
		
	
	const getYear = new Date().getFullYear();
    return (
    	<div className="profile-screen">
	<div className="container">
		<header className="header">
			<div className="row">
				<div className="menu col-sm-12">
					<div className="menu-items">
						<Link to="/my-profile"><span className="icofont-arrow-left"></span></Link>
					</div>
				</div>
				
			</div>
		</header>
		<div className="main-content">
			<div className="col-xs-12 name-hrdr">
				<h2>Edit social network<span className="orange">.</span></h2>
			</div>
			<div className={this.state.dynamiclass}>
				<ul>       
		            <li> {this.state.errors["social_media"]} </li>
		            <li> {this.state.errors["social_text"]} </li>
		                     
	            </ul>
            </div> 
            <div className={this.state.succClass}>
				<ul>       
		            <li> {this.state.succMsg["msg"]} </li>
		                     
	            </ul>
            </div> 
			<form method="POST" onSubmit= {this.socialSubmit.bind(this)} >
				<div className="infob-section social-edit-sec">
					
					<div className="form">
					
						
						{this.createUI()}        
						
						<div className="add-new" data-testid="add-social-account-button">
							+
							<span className="text" onClick={this.addClick.bind(this)}>Add social account</span>
						</div>
					</div>
					<div className="button-sec">
						<div className="action">
							<button type="submit" className="stap-button" data-testid="profile-connections-button" disabled={this.state.disabled}>{this.state.changeText}</button>
						</div>
					</div>
					
				</div>
				
				
			</form>
		</div>
		
	</div>
</div>

    );
  }
}

export default Edit_social;
